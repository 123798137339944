import React, { FC, ReactNode } from 'react';

import { Wrapper } from './styles';
import { ContentfulSection } from '../types.d';

export interface ContentSection extends ContentfulSection {
  children: ReactNode;
}

/**
 * Divider section
 */
const Divider: FC<ContentSection> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default Divider;
